<template>
  <div class="login">
    <div style="width: 100%">
      <div class="title">综合业务服务管理系统</div>
      <div class="content">
        <div
          style="
            width: 400px;
            background-color: #ffffff;
            height: 80px;
            border-radius: 5px;
            margin: 0 auto;
            opacity: 0.8;
          "
          @click="handleSwitchSystem('oa')"
        >
          <div class="content-item">
            <div style="margin-top: 30px;">
              <el-image :src="icon2" style="position: absolute; z-index: 1;height:80px;width:400px"></el-image>
              <div style="position: absolute; z-index: 2;margin-left:150px;height:80px;display: flex; align-items: center;">综合业务系统</div>
            </div>
          </div>
        </div>

        <div
          style="
            width: 400px;
            background-color: #ffffff;
            height: 80px;
            border-radius: 5px;
            margin-top: 20px;
            margin: 0 auto;
            opacity: 0.8;
          "
          @click="handleSwitchSystem('jc')"
        >
          <div class="content-item">
            <div style="margin-top: 30px">
              <el-image :src="icon1" style="position: absolute; z-index: 1;height:80px;width:400px"/>
              <div style="position: absolute; z-index: 2;margin-left:150px;height:80px;display: flex; align-items: center;">检测信息系统</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  底部  -->
    <div class="el-login-footer">
      <div style="margin-top: 10px">
        版权所有：甘肃电器科学研究院 甘公网安备
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62050202000238"
          >62050202000238号</a
        >&nbsp;&nbsp;&nbsp;&nbsp;备案号：<a href="https://beian.miit.gov.cn/"
          >陇ICP备15002755号-1</a
        >
      </div>
      <br />
      <div>地址: 甘肃省天水市秦州区长开路6-6号 邮编: 741018</div>
      <br />
      <div>
        院长电话: 0938-8381214 业务咨询: 0938-8387399 技术服务: 0938-8558014
        传真: 0938-8383344
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      avatar: require("./assets/logoImg.png"),
      icon1: require("./assets/综合监测系统.png"),
      icon2: require("./assets/综合业务系统.png"),
      oaSystemUrl: "https://yw.gsdky.com/login",
      jcSystemUrl: "https://jc.gsdky.com/login",
    };
  },
  methods: {
    handleSwitchSystem(row) {
      if (row == "oa") {
        self.location.href = this.oaSystemUrl;
      } else {
        self.location.href = this.jcSystemUrl;
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.login {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background-image: url("./assets/背景.jpg");
  background-size: cover;
}
.title {
  justify-content: center;
  font-weight: bold;
  font-size: 55px;
  text-align: center;
  color: #ffffff;
  margin-top: 160px;
}
.content {
  margin-top: 120px;
  font-size: 25px;
  color: #005cc5;
}
.el-login-footer {
  background-color: #0172ce;
  height: 90px;
  line-height: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 15px;
  letter-spacing: 1px;
}
.el-login-footer a {
  text-decoration: none;
  color: #fff;
}
</style>

